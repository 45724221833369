import React from "react"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Hero from "../components/homepage/Hero";
import {graphql, useStaticQuery} from "gatsby";
import Highlights from "../components/homepage/Highlights";
import CaseStudies from "../components/homepage/CaseStudies";
import VideoSection from "../components/VideoSection";
import Posts from "../components/homepage/Posts";
import Save from "../icons/save.svg";
import Pdf from "../icons/pdf.svg";
import Responsive from "../icons/responsive.svg";


export default function Curriculum() {

  const data = useStaticQuery(graphql`{
  homehero: file(absolutePath: {regex: "/curriculum-management-software.jpg/"}) {
    childImageSharp {
      gatsbyImageData(width: 650, layout: CONSTRAINED)
    }
  }
}
`);


  return (
    <Layout>

      <Hero
        title={'Student Handbook Software'}
        text={'Create and manage an accessible, responsive student handbook.'}
        linkText={'Get Started'}
        linkPath={'/start/'}
        buttonStyle={'button'}
        imgSrc={data.homehero.childImageSharp.gatsbyImageData}
        imgAlt={'Student using student handbook software on a laptop.'}
      />
      <Highlights
        title={'Student Handbooks that Students Love'}
        text={'Build a student handbook that students can use on any device.'}
        highlightOneIcon={<Save />}
        highlightOneTitle={'We’re Affordable'}
        highlightOneText={'We\'re typically the most affordable option for student handbook software, and we do the initial buildout for you for no additional cost.'}
        highlightTwoIcon={<Pdf />}
        highlightTwoTitle={'PDF Generation'}
        highlightTwoText={'Easily generate a PDF version of your student handbook, with no additional work required.'}
        highlightThreeIcon={<Responsive />}
        highlightThreeTitle={'Customizable & Flexible'}
        highlightThreeText={'Fully customizable designs and layouts, and workflow capabilities that allow you to set granular permissions and approval processes before content goes live.'}

      />
      <CaseStudies/>
      <VideoSection
        title={'Make Your Student Handbook Catalog Accessible'}
        text={'Learn how Clean Catalog makes your student handbook accessible and easy to navigate for all users.'}
        vimeoid={'473232701'}
      />
      <Posts
        tag={'student handbook software'}
      />


    </Layout>
  );

}

export function Head(props) {
  return (
    <Seo
      title="Student Handbook Software"
      description='Student handbook software built for colleges and universities — Clean Catalog.'
      canonical={props.location.pathname}
    />
  )
}


